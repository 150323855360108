import { BudsApi,Api } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";

export const useOpenBanking = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getAccessToken = async (uuid,source) => {
    const response = await BudsApi.post("/v1/access-token",{
            "ProjectCode":  EnvConstants.AppAdtopiaUPID,
            "Environment":  EnvConstants.AppEnv,
            "UUID": uuid,
            "is_test" : true,
            "source":source
        }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING GET ACCESS TOKEN',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const createCustomer = async (uuid,source,title,firstname,lastname,email,phone) => {
    console.log(uuid,source,title,firstname,lastname,email,phone);
    const response = await BudsApi.post("/v1/customer",{
        "ProjectCode":  EnvConstants.AppAdtopiaUPID,
        "UUID": uuid,
        "Environment": EnvConstants.AppEnv,
        "source":source,
        "is_test": "true",
        "customer_context": {
                  "type": "personal",
                  "region": "GB",
                  "locale": "en-GB"
                },
        "customer_details": {
                "salutation": title,
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "phonenumber": phone
              }
      }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING CREATE CUSTOMER',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const getProviders = async (uuid,source) => {
    const response = await BudsApi.post("/v1/providers",{
      "ProjectCode":  EnvConstants.AppAdtopiaUPID,
      "Environment":  EnvConstants.AppEnv,
      "source":source,
      "UUID": uuid,
      "is_test" : true
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING GET PROVIDER LIST',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const getAuthGatewayUrl = async (uuid,customer_id,redirect_url,provider,source) => {
    const response = await BudsApi.post("/v1/lender-authorisation-gateway-url",{
        "ProjectCode": EnvConstants.AppAdtopiaUPID,
        "Environment": EnvConstants.AppEnv,
        "UUID": uuid,
        "customer_id" : customer_id,
        "redirect_url": redirect_url,
        "is_test" : true,
        "providers": provider,
        "source":source
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING AUTHORISATION GATEWAY URL',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const callBackGateWay = async (uuid,source,status) => {
    const response = await BudsApi.post("/v1/callback-gateway-url",{
      "ProjectCode":  EnvConstants.AppAdtopiaUPID,
      "Environment":  EnvConstants.AppEnv,
      "UUID": uuid,
      "source":source,
      "status":status
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING CALLBACK GATEWAY URL',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const fetchAccountDetails = async (uuid,customer_id,source)=>{
    const response = await BudsApi.post("/v1/retrieve-accounts",{
      "ProjectCode":  EnvConstants.AppAdtopiaUPID,
      "Environment":  EnvConstants.AppEnv,
      "UUID": uuid,
      "customer_id":customer_id,
      "is_test":true,
      "source":source
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING RETRIEVE ACCOUNTS',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  }

  const fetchTransactions  = async (uuid,customer_id,from,to,source) => {
    const response = await BudsApi.post("/v1/transactions",{
      "ProjectCode":  EnvConstants.AppAdtopiaUPID,
      "Environment":  EnvConstants.AppEnv,
      "UUID": uuid,
      "customer_id" : customer_id,
      "is_test" : true,
      "source":source,
      "date_from" : from,
      "date_to" : to
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING GET TRANSACTIONS',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  }

  const storeApiResponse = async (visitor_parameters,message_type,uuid,open_banking_data) => {
    const response = await Api.post("/v1/data-ingestion-pipeline", {
      visitor_parameters,
      message_type,
      uuid,
      open_banking_data
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Data Ingestion : ' + message_type,
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };


  return { getAccessToken , createCustomer, getProviders,getAuthGatewayUrl,callBackGateWay,storeApiResponse, fetchAccountDetails, fetchTransactions };
};