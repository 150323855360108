import React ,{useState , useContext, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../assets/Thankyou/css/Thankyou.scss";
import logoWhite from '../../assets/img/logo-white.png';
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import loader from '../../assets/img/loader-review2.gif';

const BudsRedirectPage = () => {
  // localStorage.clear();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const [status,setStatus] = useState('');
  const [loaderStatus,setLoaderStatus] = useState(true);
  const [callBackStatus,setCallbackStatus] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { callBackGateWay,storeApiResponse,fetchAccountDetails,fetchTransactions } = useOpenBanking();
  const query = new URLSearchParams(window.location.search);
  const task_id = query.get("task_id");
  const query_uuid = query.get("uuid");
  const local_storage_uuid = localStorage.getItem('uuid');
  var uuid = (query_uuid)? query_uuid : local_storage_uuid; 
  const query_source = query.get("atp_sub6");
  var source = (query_source) ? query_source : "live";
  const query_status = query.get("status");
  var customer_id = localStorage.getItem("customer_id");
  let from_date = "2023-05-26";
  let to_date   = "2023-05-26";
  let payload = {};
  useEffect(() => {
    (async () => {
      let status = (query_status && query_status == "success") ? 1 : 2; 
      payload = {"milestone_key":"bank_connection","source":source,"value":status};
      if(query_status && query_status == "success"){
          setStatus("Fetching Account Details......");
          await storeApiResponse(visitorParameters.visitor_parameters,"op_milestone_update",uuid,payload);
      }else if(query_status && query_status == "failure"){
        setLoaderStatus(false);
        await storeApiResponse(visitorParameters.visitor_parameters,"op_milestone_update",uuid,payload);
      }
      const response = await callBackGateWay(uuid,source,query_status);
      if(response.data.status == "success"){
        // setCallbackStatus(true);
        setStatus("Fetching Transactions......");
        payload = {"milestone_key":"callback_url","source":source};
        const callbackResponse = await storeApiResponse(visitorParameters.visitor_parameters,"op_milestone_update",uuid,payload);
        if(task_id != null && callbackResponse.data.status == "Success"){
            const accountResponse = await fetchAccountDetails(uuid,customer_id,source);
            if(accountResponse.data.status == "success"){
              payload = {"milestone_key":"account_fetch","source":source};
              const storeAccount = await storeApiResponse(visitorParameters.visitor_parameters,"op_milestone_update",uuid,payload);
              if(storeAccount.data.status == "Success"){
                const transactionResponse = await fetchTransactions(uuid,customer_id,from_date,to_date,source);
                if(transactionResponse.data.status == "success"){
                  setLoaderStatus(false);
                  payload = {"milestone_key":"transaction_fetch","source":source};
                  await storeApiResponse(visitorParameters.visitor_parameters,"op_milestone_update",uuid,payload);
                }
              }
            }
          
        }
      }
    })();
  },[]);

  setTimeout(() => {
    setLoaderStatus(false);
  }, 3000);
  const modalClick = (clickValue) => {
    switch (clickValue) {
     
      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showCookie": {
        setShowCookie(true);
        break;
      }
      case "exitCookie": {
        setShowCookie(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
    }
  };
  return (
    <div className='thankyou'>
      <header>
         <div className="container">
            <div className="col-xl-12 text-center">
                  <img src={logoWhite} alt=""/>
            </div>
         </div>
      </header>

      <section>
         <div className="container">
            <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 text-center">
            {loaderStatus ? <div className="col-lg-12 col-12 text-center loader mt-4">
                  <img  src={loader} alt=""/> 
                  <p>
                    {status}
                  </p>
                </div>: 
                <div>
               <h5 className="mb-3">
                 THANK YOU!
               </h5>
               <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
               </p>
               </div>}
            </div>
         </div>
      </section>


      <footer className="fixd-ftr">
         <div className="container">
            <div className="col-xl-8 col-lg-8 text-start">
               <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
               <ul>
                  <li><a href="#" data-bs-toggle="modal" onClick={() => modalClick("showPrivacy")}>Privacy and Cookie Policy</a></li>
                  <li><a href="#" data-bs-toggle="modal" onClick={() => modalClick("showCookie")}>Terms and Conditions</a></li>
                  <li><a href="#" data-bs-toggle="modal" onClick={() => modalClick("showComplaint")}> Complaints Procedure</a></li>
               </ul>
            </div>
         </div>
      </footer>
      <Modal show={showPrivacy} size="xl" id="privacy"  className="modal fade">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">PRIVACY AND COOKIE POLICY</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={() => modalClick("exitPrivacy")}></button>
              </div>
              <div className="modal-body">
                <h3>Privacy Policy</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={() => modalClick("exitPrivacy")}>Close</button>
              </div>
            </div>
        </Modal>
        <Modal show={showCookie} size="xl" className="modal fade" id="terms">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Terms and Conditions</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => modalClick("exitCookie")}></button>
              </div>
              <div className="modal-body">
                <h3>Terms and Conditions</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => modalClick("exitCookie")}>Close</button>
              </div>
            </div>
        </Modal>
        <Modal show={showComplaint} size="xl" className="modal fade" id="complaints">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">COMPLAINTS PROCEDURE</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => modalClick("exitComplaint")}></button>
              </div>
              <div className="modal-body">
                <h3>Complaints Procedure</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => modalClick("exitComplaint")}>Close</button>
              </div>
            </div>
        </Modal>
    </div>
  )
}

export default BudsRedirectPage;
