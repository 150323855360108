import React, { useState } from "react";
import loader from "../../../../src/assets/img/ACA_SPH_V5/loader.gif";
import Tapstone from "../../../Utility/Tapstone";

const SimpleModal = ({
  modalBody,
  setModalBody,
  ModalViewers,
  showLoader,
  buttonText,
  splitName,
  undoChat,
}) => {
  const query = new URLSearchParams(window.location.search);
  const atp_source = query.get("atp_source") || "";
  return (
    <>
      <div
        className={`modal fade unqlifiedmodal-pop ${
          modalBody === "" ? "hide" : "show"
        }`}
        tabIndex={-1}
        role="dialog"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content bor-b5">
            {/* <div className='modal-header noborder' /> */}
            <div className="modal-body">
              <p className="unqualify-modal-p text-center fw-bold mt-4">
                {modalBody}
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              {!showLoader ? (
                splitName !== "Spanish" ? (
                  ""
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary btn-anime btn_reClaim"
                    data-dismiss="modal"
                    onClick={(e) => {
                      ModalViewers(1);
                    }}
                  >
                    {buttonText}
                  </button>
                )
              ) : (
                <div className="msg-loader loader1">
                  <div className="text-msg">
                    <img src={loader} alt="" />
                  </div>
                </div>
              )}
            </div>
            {/* {(splitName === "Spanish") && (
              <div className={`${modalBody ? 'show' : 'hide'}`}>
                <Tapstone
                  tag2={atp_source}
                />
              </div>
            )} */}
            <div className="modal-footer justify-content-center"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleModal;
