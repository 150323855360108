import React, { useEffect, useReducer, useState, useRef } from "react";
import arrow from "../../assets/img/arrow-down1.png";
import { questions } from "../../Constants/Constant_SPH_V7";
import Questionnaire from "../Includes/Layouts/ACA_SPH_V77/Questionnaire";
import Loader from "../Includes/Layouts/ACA_SPH_V77/Loader";
import Call_button from "../Includes/Layouts/ACA_SPH_V77/Call_Button";
import { useForm } from "react-hook-form";
import useJourneyStats from "../../Hooks/useJourneyStats";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useContext } from "react";
import { queryString } from "../../Utility/QueryString";
import OfflineSpanishButtonLinkSpanish from "../../Utility/OfflineSpanishButtonLinkSpanish";

const FormSplit_ACA_SPH_V7 = ({ setModalBody, splitName, setShow, response }) => {
  console.log(response, 2546);
  const { register, errors, clearErrors, setError, trigger, getValues } =
    useForm({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const initialState = {};
  const First_qn = questions[0].questionId;

  questions.forEach((question) => {
    initialState[`showQuestionSlide${question.questionId}`] = "d-none";
  })

  initialState[`showQuestionSlide${First_qn}`] = "d-block";
  initialState['section'] = "d-block";
  initialState['Loader'] = 'd-none';
  initialState['call_button'] = 'd-none';
  console.log(initialState);
  const QuestionReducer = (state, action) => {
    switch (action.type) {
      case "nextQuestionSlide": {
        if (action.payload.clickedSlide.slide == "question_21" && action.payload.clickedSlide.value == 50) {
          return {
            ...state,
            showQuestionSlide21: "d-none",
            showQuestionSlide22: "d-block"
          }
        }
        else if (action.payload.clickedSlide.slide == "question_21" && action.payload.clickedSlide.value == 49) {
          return {
            ...state,
            showQuestionSlide21: "d-none",
            showQuestionSlide15: "d-block"
          }
        }
        else if (action.payload.clickedSlide.slide == "question_15" || action.payload.clickedSlide.slide == "question_22") {
          setShow("d-none");
          return {
            ...state,
            showQuestionSlide15: "d-none",
            showQuestionSlide22: "d-none",
            section: "d-none",
            Loader: "d-block"
          }

        }
        else if (action.payload.clickedSlide.slide == "call_button") {
          return {
            call_button: "d-block",
            Loader: "d-none",
            showQuestionSlide21: "d-none",
            showQuestionSlide15: "d-none",
            showQuestionSlide22: "d-none",
            section: "d-none",
          }
        }
        else {
          return {
            ...state
          }
        }
      }
    }
  }
  const [state, dispatch] = useReducer(QuestionReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState();
  const saveJournyStats = useJourneyStats();
  const splitForm = useRef(null);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const [buttonRedirect, setButtonRedirect] = useState();
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("uuid") ? query.get("uuid") : localStorage.getItem("uuid");
  let is_online = response.is_online;
  let redirect = response.redirect_path;
  const journy = async (uuid, slide_type, slide_question, slide_value, type, split_name) => {
    await saveJournyStats(
      uuid,
      slide_type,
      slide_question,
      slide_value,
      type,
      split_name
    )
  }
  const saveJounrney = async (e, radioName) => {
    await saveJournyStats(
      uuid, "question", radioName, e.target.value, "click", splitName
    )

  }
  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLinkSpanish(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);

  useEffect(() => {
    if (state.showQuestionSlide21 == "d-block") {
      if (uuid) {
        journy(uuid, "question", 21, "", "view", splitName)
      }
    }
  }, [uuid])

  useEffect(() => {
    if (clickedSlide) {
      if (clickedSlide.slide == "question_21" && clickedSlide.value == 50) {
        journy(uuid, "question", 22, "", "view", splitName)
      }
      if (clickedSlide.slide == "question_21" && clickedSlide.value == 49) {
        journy(uuid, "question", 15, "", "view", splitName)
      }
      if (clickedSlide.slide == "call_button") {
        journy(uuid, "button", "call_click", "", "view", splitName)
      }
    }
  }, [clickedSlide])

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  const slideChange = async (e, radioName) => {
    let value = null;
    const values = getValues();
    // window.scrollTo(0, 145);
    window.scrollTo(0, 0);
    value = (radioName == "call_button") ? value : e.target.value;
    if (radioName == "question_22" && value == "52") {
      let quest_id = radioName.split('_')[1];
      await journy(uuid, "question", quest_id, value, "click", splitName);

      const decodedQueryString = decodeURIComponent(query.toString());
      var redirectUrl = `${window.location.origin}/greencard-thankyou?${decodedQueryString}`;
      window.location.href = redirectUrl;
      return;
    }
    (radioName == "call_button") ? setClickedSlide({ slide: radioName }) : setClickedSlide({ slide: radioName, value: e.target.value });
    if (radioName != "call_button") {
      let quest_id = radioName.split('_')[1];
      journy(uuid, "question", quest_id, value, "click", splitName);
    }
    if ((radioName == "question_22") && radioName != "call_button") {
      const formData = values;
      formData.lstSalutation = '';
      formData.txtFName = '';
      formData.txtLName = '';
      formData.txtEmail = '';
      formData.txtPhone = '';
      formData.page_name = splitName;
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        splitName,
        queryString,
        visitorParameters.data
      );
    }

  }
  return (
    <section className="container-fluid form-bg">
      <form action="" className="form_slides">
        <div className="col-lg-6 col-12">
          {/* ============ SLIDE 1 PAGE =========== */}
          <div className={`animated-arrow col-6 ${state.section}`}>
            <img
              src={arrow}
              alt=""
              className="animated-arrow-img animg"
            />
          </div>
          <form ref={splitForm}>
            <Questionnaire
              showQuestionSlide={state.showQuestionSlide21}
              question={questions[0]}
              slideChange={slideChange}
              backBtn={false}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              splitName={splitName}
              setModalBody={setModalBody}
              saveJounrney={saveJounrney}
            />
            <Questionnaire
              showQuestionSlide={state.showQuestionSlide22}
              question={questions[1]}
              slideChange={slideChange}
              backBtn={false}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              splitName={splitName}
            />
            {/* <Questionnaire
              showQuestionSlide={state.showQuestionSlide15}
              question={questions[2]}
              slideChange={slideChange}
              saveJounrney={saveJounrney}
              backBtn={false}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              splitName={splitName}
              setModalBody={setModalBody}
            /> */}
            {state.Loader == "d-block" ? <Loader slideChange={slideChange} /> : null}
            <Call_button
              showQuestionSlide={state.call_button}
              is_online={is_online}
              buttonRedirect={buttonRedirect}

            />
          </form>
        </div>
      </form>
    </section>
  )
}
export default FormSplit_ACA_SPH_V7;