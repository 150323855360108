import axios from "axios";
import {AppAdtopiaToken,
  AppPostCodeSingleApi,
  AppEmailValidateSingleApi,
  AppApiUrl,
  AppSinglePointPhoneValidationURL,
  AppBudsSingleApi,
  AppGmailWebhookApi
  } from "../Constants/EnvConstants";

require("dotenv").config();

export const Api = axios.create({
  baseURL: AppApiUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});

export const CommonEndApi =  axios.create({
  baseURL : AppPostCodeSingleApi,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const CommonEmailValidateApi =  axios.create({
  baseURL : AppEmailValidateSingleApi,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const IdUploadApi =  axios.create({
  baseURL: AppApiUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});

export const CommonPhoneValidation =  axios.create({
  baseURL : AppSinglePointPhoneValidationURL,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const BudsApi =  axios.create({
  baseURL : AppBudsSingleApi,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const GmailWebhookApi = axios.create({
  baseURL: AppGmailWebhookApi,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});