import React, { useEffect, useState } from "react";

const Loader = (props) => {
  const [loader, setLoader] = useState(null);
  const [show, setShow] = useState("d-none");
  const [currentIndex, setCurrentIndex] = useState(0);
  let loaderArr = ["Revisando tus respuestas", "Conexión al portal del programa", "Comprobación de programas locales"];
  useEffect(() => {
    if (loaderArr.length >= currentIndex) {
      setTimeout(() => {
        setLoader(loaderArr[currentIndex]);
        setShow("d-block");
        setCurrentIndex((prevIndex) => prevIndex + 1);
        console.log(currentIndex);
      }, 2000);
      setTimeout(() => {
        console.log(12355);
        if (show != "d-none") {
          setShow("d-none");
        }
      }, 1000)
    }
    else {
      props.slideChange("", "call_button");
    }
  }, [currentIndex]);

  return (
    <>
      <div
        id="slide_load1"
        className={`slide_thk boxme animate fadeIn five mb-5 ${show}`}
      >
        <div className="form_sec text-center  mb-5">
          <p className="m-0">
            <strong>{loader}</strong>
          </p>
        </div>
      </div>
    </>
  )
}
export default Loader;