//     extra parameters     //
//     ----------------     //
//  -> first_question       //
//  -> final_question       //
//  -> specialcss           //
//////////////////////////////

export const QandA_v6B = [
  {
    id: '20',
    first_question: true,
    question: [`Hola!`, `¿Quieres ver si calificas?`],
    options: [{ id: '47', text: 'Si, Vamos!' }],
  },
  {
    id: '21',
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: '50', text: 'No' },
      { id: '49', text: 'Si' },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: '15',
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: 'verticleButton',
    options: [
      { id: '30', text: 'Medicare' },
      { id: '31', text: 'Medicaid' },
      { id: '32', text: 'Medicare & Medicaid' },
      { id: '33', text: 'VA' },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: '22',
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: '51', text: 'Si' },
      { id: '52', text: 'No' },
      // show popup for no
    ],
  },
  {
    id: 'CALL_CLICK',
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para las facturas del hogar, el entretenimiento, los viajes y el ocio!`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios.`,
      `Sólo te llevará de 3 a 5 minutitos.`,
    ],
    options: [{ id: '', text: '833-506-3463' }],
  },
];
