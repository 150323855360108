import React from "react";
import logo from "../../../../../assets/img/logo.png"
import { Helmet } from "react-helmet";
import * as EnvConstants from "../../../../../../src/Constants/EnvConstants";
const EnvConstant = EnvConstants.AppEnv;
const Header = () =>{
    return(
        <>
        {(EnvConstant == "prod") ? <Helmet><script src="//b-js.ringba.com/CAfe8e30f0d9964386bac7acabadf4a10b" async></script></Helmet> : <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}
         <header>
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header;