import React, { useState, useEffect } from 'react';
import { RingbaCheckUUID } from '../../Utility/RingbaCheckUUID';
import { Helmet } from 'react-helmet';
import { GreencardPhoneNumberConstants } from '../../Constants/PhoneNumberConstants';
import "../../assets/css/greencard-medicare-ty.scss";
import Time from "../Includes/Layouts/ACA_SPH_V6_SB_1/Time";
import { useUserClicks } from '../../Hooks/useUserClicks';
import useJourneyStats from "../../Hooks/useJourneyStats";
import OfflineSpanishButtonLink from "../../Utility/OfflineSpanishButtonLinkSpanish";
import RingbaApi from '../../Utility/RingbaApi';
import { useDataIngestion } from '../../Hooks/useDataIngestion';

const GreencardThankyou = () => {
    const campaign_name = 'spanish';

    const { saveDataIngestion } = useDataIngestion();
    const { isCheck, } = RingbaCheckUUID();
    isCheck();
    const { response } = RingbaApi(campaign_name);
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get('uuid');
    const utmName = (query.get('utm_campaign')) ? query.get('utm_campaign') : 'default';
    const [formattedNumber, setFormattedPhoneNumber] = useState('');
    const utm_phonenumber = (GreencardPhoneNumberConstants[utmName]) ? GreencardPhoneNumberConstants[utmName] : GreencardPhoneNumberConstants['default'];
    const [phoneNumber, setPhoneNumber] = useState(utm_phonenumber);
    const { saveUserClicks } = useUserClicks();
    const saveJournyStats = useJourneyStats();

    const [buttonRedirect, setButtonRedirect] = useState({});
     let is_online = response.is_online;
     let redirect = response.redirect_path;
    
    useEffect(() => {
        if (response) {
          const buttonofflineLink = OfflineSpanishButtonLink(redirect);
          setButtonRedirect(buttonofflineLink);
        }
      }, [response]);


    useEffect(() => {
        if (phoneNumber) {
            const digits = phoneNumber.replace(/\D/g, '').slice(-10);
            if (digits.length === 10) {
                const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                setFormattedPhoneNumber(formattedNumber);
            }
        }
    }, [phoneNumber]);

    const saveUser = async (name, status) => {
        await saveUserClicks(uuid, name, status);
    };
    const splitName = localStorage.getItem('split_name');
    useEffect(() => {
        (async () => {
            await saveJournyStats(
                uuid,
                "BUTTON",
                "GREENCARD_CLICK",
                "",
                "view",
                splitName
            );
        })();
    }, []);
    const saveCallClick =()=>{
        saveUser('GREENCARD_CLICK', 1);
        (async () => {
            await saveJournyStats(
              uuid,
              "BUTTON",
              "GREENCARD_CLICK",
              "",
      
              "click",
              splitName
            );
          })();
    }
    const handleRedirect = async (click_status, click_value) => {
        const ringbaSubmit = await saveDataIngestion(
          uuid,
          [click_status, click_value],
          "offline",
          splitName
        );
        window.location.href= buttonRedirect.buttonRedirectionLink
    }

    return (
        <>
            <Helmet>
                <script src="//b-js.ringba.com/CAee7b2b5cb2f6405ca2e4500f2c8e8e0b" async></script>
            </Helmet>
            <div className="greencard-medicare-ty">
                <div className="popup-wrap">
                    <div className="popup">
                        <div className="text-popup">
                            <h5>
                                <b>¡Felicidades! 🤩🤩🥳🥳🥳</b>
                            </h5>
                            <h6>
                                ¡Calificas para una llamada de asesoramiento gratuita para conocer tus opciones y poder obtener un estatus migratorio legal!
                            </h6>
                            <h6>
                                Si te gusta lo que escuchas, este socio cuidadosamente seleccionado ofrece sus servicios a precios mucho más bajos que los que obtendrías de un bufete de abogados y estará contigo en cada paso del camino para cambiar tu vida.
                            </h6>
                            <h6>
                                Mantendremos la línea abierta durante 60 segundos.
                            </h6>
                            <h6>
                                1 agente disponible Cuenta regresiva desde <span className='text-danger'><Time minuteLoad="0" timeLoad={true} /></span>
                            </h6>
                        </div>
                        <div className="btn-div">
                        {is_online == 'offline'? 
                            ( <a  onClick={()=>handleRedirect(1,"call_offline")}>
                            <button className="anime-pulse">
                                Reclamar Beneficios
                            </button>
                        </a>)
                           :
                           ( <a href={`tel: ${phoneNumber}`} onClick={saveCallClick}>
                                <button className="anime-pulse">
                                    {formattedNumber}
                                </button>
                            </a>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GreencardThankyou;
