export const PhoneNumberConstants = {
    "default": "+18775360756",
    "ACA_V1_FB_JAMES": "+18775360756",
    "ACA_US_ESS1_META_FB_ART": "+18553940298",
    "ACA_US_ESSM_META_FB_GEOFF": "+18556730704",
    "default_SPH": "+18335063463",
    "ACA_US_ESP_FB_Art": "+18884140368",
    "ACA_US_ESP_FB_Geoff": "+18884141283",
    "ACA_US_ESP_FB_ART": "+18884140368",
    "ACA_US_ESP_FB_GEOFF": "+18884141283",
    "DEV_TEST_ACA_ESP_ART": "+18884140368",
    "DEV_TEST_ACA_ESP_GEOFF": "+18884141283",
    "PRE_TEST_ACA_ESP_ART": "+18884140368",
    "PRE_TEST_ACA_ESP_GEOFF": "+18884141283",
    "ACA_ENG_FB_CB_VAN": "+18556151793",
    "ACA_ESP_FB_CB_VAN": "+18556151799",
    "ACA_ENG_FB_META_ANTONIO": "+18444551366",
    "ACA_ESP_GEO_META_ART": "+18884140368",
    "ACA_BENEFITS_ESP_FB_BRIANNE": "+18333340595",
    "default_SPHV7B": "+18335593928",
    "ACA_BENEFITS_ESP_FB_RAUL": "+18333162345",
    "ACA_BENEFITQUIZ_ESP_TIKTOK_BRIANNE": "+18333340595",
    "ACA_BENEFITS_ESP_TRIAL6_FB_ADAM": "+18665977183",
    "ACA_BENEFITS_ESP_B1_FB_ADAM": "+18665977183",
    "ACA_US_ESP_FB_JMS": "+18775080158",
    "ACA_ESP_META_JAMES": "+18775080158",
    // "ACA_ESP_Meta_Acc2_James":"+18775080158"
    "ACA_ESP_META_ACC2_JAMES": "+18775080158",
    "ACA_ESP_FB_LPC_SAMUEL": "+18775630671",
    "ACA_3_US_ESP_FB_ART": "+18884140368",
    "ACA_BQ_ESP_FB_ADSYNC": "+18666450104",
    "ACA_ESP_TAB_JMS": "+18775080158",
    "ACA_ES_GEO_FB_ADAM": "+18665977183",
    "ACA_ES_GEO_FB_JMS": "+18775080158",
    "ACA_ESP_TK_JMS": "+18775080158",
    "default_local_NO": "+18554531966",
    "ACA_ESP_GEO_MARRIED_FB_ART":"+18884140368",
    "ACA_BQ_ESP_TK_ART":"+18884140368",
    "ACA_BQ_ES_V1_FB_GERSON":"+18664540673",
    "ACA_BQ_ES_V1_FB_SEB" : "+18776491007",
    "ACA_BQ_ES_V1_FB_FELIPE" : "+18446581400",
    "ACA_ESP_GEO_META_ADV_ART" : "+18884140368",
    "ACA_BQ_ES_V1_FB_SEB" : "+18886460390",
    "ACA_BQ_ES_V1_FB_CAROLINA" : "+18339760127",
    "ACA_BQ_META_FB_ART" : "+18884140368",
    "ACA_BQ_ES_V1_FB_KAI" : "+18888851335",
    "ACA_BQ_ES_BSV1_FB_KAI" : "+18888851335",
    "ACA_BQ_ES_V1_FB_VAF" : "+18775081671",
    "ACA_BQ_ES_V1_FB_JRL" : "+18446023481",
    "ACA_BQ_ES_TRIAL6_FB_KAI" : "+18888851335",
    "ACA_BQ_ES_T6_FB_SAN":"+18554676050",
    "ACA_BQ_VAN_ESP_FB_VAF":"+18775081671"
};

export const GreencardPhoneNumberConstants = {
    "default": "+18885380531",
    "ACA_BENEFITS_ESP_B1_FB_ADAM" : "+18446471346",
    "ACA_ES_GEO_FB_ADAM" : "+18446471346",
    "ACA_ES_GEO_FB_JMS" : "+18557141034",
    "ACA_ESP_META_JAMES" : "+18557141034",
    "ACA_US_ESP_FB_JMS" :  "+18557141034",
    "ACA_US_ESP_FB_ART" : "+18446530324",
    "ACA_ESP_GEO_META_ART" : "+18446530324",
    "ACA_ESP_GEO_MARRIED_FB_ART" :"+18446530324",
    "ACA_BQ_ESP_TK_ART":"+18446530324",
    "ACA_BQ_ES_V1_FB_SEB" :"+18666108826",
    "ACA_BQ_ES_V1_FB_CAROLINA":"+18447200092",
    "ACA_BQ_META_FB_ART":"+18446530324",
    "ACA_BQ_ES_V1_FB_KAI":"+18886114257",
    "ACA_BQ_ES_BSV1_FB_KAI" : "+18886114257",
    "ACA_BQ_ES_V1_FB_VAF" : "+18447191967",
    "ACA_BQ_ES_V1_FB_JRL" : "+18777168076",
    "ACA_BQ_ES_TRIAL6_FB_KAI" : "+18886114257",
    "ACA_ESP_GEO_META_ADV_ART" : "+18446530324",
    "ACA_ESP_FB_LPC_SAMUEL" : "+18554973175",
    "ACA_ESP_BQ_TK_SAMUEL" : "+18554973175",
    "ACA_BQ_ES_T6_FB_SAN":"+18665754050",
    "ACA_BQ_VAN_ESP_FB_VAF":"+18447191967"
};
export const MedicarePhoneNumberConstants = {
    "default": "+18339601544",
    "ACA_BENEFITS_ESP_B1_FB_ADAM": "+18776992280",
    "ACA_ES_GEO_FB_ADAM": "+18776992280",
    "ACA_ES_GEO_FB_JMS": "+18333092931",
    "ACA_ESP_META_JAMES": "+18333092931",
    "ACA_US_ESP_FB_JMS": "+18333092931",
    "ACA_US_ESP_FB_ART": "+18446550358",
    "ACA_ESP_GEO_META_ART": "+18446550358"


};
