import React, { useState, useEffect } from "react";
import arrow from "../../../assets/img/arrow-down1.png";
import FormSplit_ACA_SPH_V7 from "../../Forms/FormSplit_ACA_SPH_V7";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import FooterSPH from "../../Includes/Layouts/Common/FooterSPH";
import SimpleModal from "../SimpleModal";
import { useRingbaInfo } from "../../../Hooks/useRingbaInfo";
import Header from "../../Includes/Layouts/ACA_SPH_V77/Header";
import "../../../assets/css/ACA_SPH_V7.scss"

const ACA_SPH_V7 = () => {
  const [show, setShow] = useState("d-block");
  const [modalBody, setModalBody] = useState('');
  const { saveDataIngestion } = useDataIngestion();
  const [showLoader, setShowLoader] = useState("d-none");
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("uuid");
  const unqualifiedLink = UnqualifiedRedirectionLinkSpanish();
  const { postRingbaInfo } = useRingbaInfo();
  const [response, setResponse] = useState("");

  const campaign_name = "spanish";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await postRingbaInfo(campaign_name);
        setResponse(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);



  const ModalViewers = async (click_status) => {
    setShowLoader("d-block");

    const viewersSubmit = await saveDataIngestion(
      uuid,
      [click_status],
      "unqualify_modal",
      "ACA_SPH_V7",
    );
    window.location = unqualifiedLink.redirectionLink;
  }
  return (
    <>
      <div className="ACA_SPH_V7">
        <Header />
        <AdtopiaLoadLP PageType="LP" splitName="ACA_SPH_V7" />
        <FbViewPixel />
        <main>
          <scetion className="Seguro__Latinos">
            <div className="main_flic">
              <div className={`container tsec ${show}`}>
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-11 col-12">
                    <div className="inner_content text-center">
                      <h2>
                        Desbloquea hasta $7200 en Subsidios Fiscales para obtener un
                        Seguro Medico por $0 al mes!
                      </h2>
                    </div>
                    <div className="inner_bg">
                      {/* <p className="text-center m-0">
                        <strong>Imagina lo que puedes hacer con los ahorros</strong>
                      </p>
                      <ol className="gradient-list">
                        <li> Gasolina </li>
                        <li> Renta </li>
                        <li> Comida </li>
                        <li> Disfrutar la vida </li>
                      </ol> */}
                      <p className="text-center mb-0">
                        <strong>Vamos a Ver si Calificas </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <FormSplit_ACA_SPH_V7
                splitName="ACA_SPH_V7"
                setShow={setShow}
                setModalBody={setModalBody}
                response={response}
              />
              <div className="col-12 mb-5 mt-5 spaced" style={{ display: "none" }}>
                &nbsp;
              </div>
              <FooterSPH />
              <SimpleModal
                splitName="Spanish"
                modalBody={modalBody}
                setModalBody={setModalBody}
                ModalViewers={ModalViewers}
                showLoader={showLoader}
              />
            </div>
          </scetion>
        </main>
      </div>
    </>

  )
}
export default ACA_SPH_V7;