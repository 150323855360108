// import { lazy } from 'react';
// const Split_1 =  import('./Components/Pages/Splits/Split_1');

// export {
//     Split_1
// }

import React, { lazy } from 'react';

const ACA_SPH_V6B = lazy(() => import('./Components/Pages/ACA_SPH_V6B/ACA_SPH_V6B'));
const ACA_SPH_V8_4 = lazy(() => import('./Components/Pages/ACA_SPH_V8_4/ACA_SPH_V8_4'));
const ACA_SPH_V10 = lazy(() => import('./Components/Pages/ACA_SPH_V10/ACA_SPH_V10'));

const AppSplitList = (props) => {

    const SplitListMap = {
        'ACA_SPH_V6B': ACA_SPH_V6B,
        'ACA_SPH_V8_4': ACA_SPH_V8_4,
        'ACA_SPH_V10':ACA_SPH_V10
    };
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;