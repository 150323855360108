import React, { useEffect, useState } from "react";
import { useUserClicks } from "../../../../../Hooks/useUserClicks";
import { useDataIngestion } from "../../../../../Hooks/useDataIngestion";
import { useContext } from "react";
import { queryString } from "../../../../../Utility/QueryString";
import VisitorParamsContext from "../../../../../Contexts/VisitorParams";
import GTMDataLayer from "../../../../../Components/Includes/Layouts/Common/GTMDataLayer";
import useJourneyStats from "../../../../../Hooks/useJourneyStats";
import { PhoneNumberConstants } from "../../../../../Constants/PhoneNumberConstants";
import * as EnvConstants from "../../../../../../src/Constants/EnvConstants";

const CallButtonSpanishTwo = ({telephone},props) => {
    const { saveUserClicks, isLoading } = useUserClicks();
    const { saveDataIngestion } = useDataIngestion();
    const saveJournyStats = useJourneyStats();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get("uuid");
    const splitName = localStorage.getItem("split_name");
    const utmName = query.get("utm_campaign");
    const [phoneNumber,setPhoneNumber] = useState('');
    const EnvConstant = EnvConstants.AppEnv;

    useEffect(()=>{
        if(EnvConstant == "prod"){
            if(PhoneNumberConstants[utmName]==undefined){
            setPhoneNumber(PhoneNumberConstants["default_SPH"]);
            }else{
                setPhoneNumber(PhoneNumberConstants[utmName]);
            }
        }else{
            setPhoneNumber(PhoneNumberConstants['default_local_NO']);
        }
    },[utmName]);
    console.log(phoneNumber);
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    useEffect(() => {
        if (phoneNumber) {
            const digits = phoneNumber.replace(/\D/g, '').slice(-10);
            if (digits.length === 10) {
            const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            setFormattedPhoneNumber(formattedNumber);
            }
        }
    }, [phoneNumber]);

    const handleClick = async (e, name) => {
         GTMDataLayer({
            question: "Call now",
            answer: "",
          });
        const clickSubmit = await saveUserClicks(
            uuid,
            name,
            1
        );
        await saveJournyStats(
            uuid,
            "button",
            "call_click",
            "",
            "click",
            splitName
        );
        const formData = [];
        formData.lstSalutation = '';
             formData.txtFName = '';
              formData.txtLName = '';
              formData.txtEmail = '';
              formData.txtPhone = '';
              formData.page_name = 'ACA_BQ_V1';
        const pixelSubmit = await saveDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            "pixel_message",
            "aca_bq",
            queryString,
            visitorParameters.data,
        )
    }
    return (
        <>
            <a className="call icon_option_num icon_option_num_a text-white" onClick={(e) => handleClick(e, "call_click")} href={`tel: ${phoneNumber}`}> <img src={telephone} alt="" />{formattedPhoneNumber}</a>
        </>
    )
}
export default CallButtonSpanishTwo;