import React,{useState} from 'react'
import Footer from '../../Includes/Layouts/ACA_SPH_V4/Footer'
import Timee from "../../Includes/Layouts/Common/Timee";
import Helmet from 'react-helmet';
import CallButtonSpanishTwo from "../../Includes/Layouts/Common/ACA_BQ_1/CallButtonSpanishTwo";
import Telephone from "../../../assets/img/ACA_SPH_V4/call.png";
import * as EnvConstants from "../../../../src/Constants/EnvConstants";

const Thankyou = (props) => {
  const [showTime, setShowTime] = useState(false);
  const EnvConstant = EnvConstants.AppEnv;
  window.scrollTo(0, 0);
  setTimeout(() => {
    setShowTime(true);
}, 2000);

  return (
   
    <div className='ACA_SPH_V4'>
  {(EnvConstant == "prod") ? <Helmet>
      <script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script>
  </Helmet> : <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}

  <section className="Thank_ACAques">
    <div className="ContThax_sec container">
      <div className="row">
        <div className="col-12">
          <div className="WeKXr_Bxt">
            <div className="Thank_conSec">
              <h1>Felicidades, ¡Eres Elegible!</h1>
              <h3>
                <b>Último Paso:</b> Debes{" "}
                <u>
                  <b>Llamar Ahora</b>
                </u>{" "}
                para obtener tu Póliza de Seguro Médico por $0. ¡Obtén
                beneficios médicos para ti y tu familia gratuitos de este
                Programa Federal!
              </h3>
              <h4>Llámanos ahora para obtener tu póliza gratuita</h4>
              <h2>TOCA PARA LLAMAR</h2>
              <div>
              <CallButtonSpanishTwo splitName={props.splitName} telephone={Telephone} />
              </div>
              <p className="__texNote">
                ¡Actúa Rápido! La preaprobación termina en :{" "}
                <span
                  id="countdown"
                  style={{ fontWeight: "bold", color: "red" }}
                >

{showTime && <Timee
                                    timeLoad={showTime}
                                />}
                                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<Footer />


    </div>
  )
}

export default Thankyou