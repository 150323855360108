import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
// import Questionnaire from "./Components/Pages/Questionnaire";
import Preview from "./Components/Pages/Preview";
import OpenBankingProceed from "./Components/Pages/OpenBankingProceed";
import Unqualified from "./Components/Pages/Unqualified";
import Signature from "./Components/Pages/Signature";
import Thankyouu from "./Components/Pages/ACA_SPH_V4/Thankyou";
import PreviewLoa from "./Components/Pages/PreviewLoa";
import NotFound from "./Components/Pages/NotFound";
import BudsRedirectPage from "./Components/Pages/BudsRedirectPage";
import Proceed from "./Components/Pages/Proceed";
import PdfView from "./Components/Pages/PdfView";
import Privacy_policy from "./Components/Includes/Layouts/Common/Privacy_policy";
import Terms_of_use from "./Components/Includes/Layouts/Common/Terms_of_use";
import Cookies_policy from "./Components/Includes/Layouts/Common/Cookies_policy";
import ACA_SPH_V6B from "./Components/Pages/ACA_SPH_V6B/ACA_SPH_V6B";
import AdtopiaLoadValue from "./Components/Pages/AdtopiaLoadValue";
import ACA_SPH_V8_4 from "./Components/Pages/ACA_SPH_V8_4/ACA_SPH_V8_4";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import GreencardThankyou from "./Components/Pages/GreencardThankyou";
import MedicareThankYou from "./Components/Pages/MedicareThankYou";
import ACA_SPH_V10 from "./Components/Pages/ACA_SPH_V10/ACA_SPH_V10";
import MedicareThankYouV2 from "./Components/Pages/MedicareThankyouV2";
import ACA_SPH_V11 from "./Components/Pages/ACA_SPH_V11/ACA_SPH_V11";
import ACA_BQ_ADV1 from "./Components/Pages/Advertorials/ACA_BQ_ADV1";
import Política_de_Cookies from "./Components/Includes/Layouts/Common/Política_de_Cookies";
import Política_de_Privacidad from "./Components/Includes/Layouts/Common/Política_de_Privacidad";
import Terminos_de_Uso from "./Components/Includes/Layouts/Common/Terminos_de_Uso";
import ACA_SPH_V7 from "./Components/Pages/ACA_SPH_V7/ACA_SPH_V7";

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              {/* <Route exact path={"/questionnaire"} component={Questionnaire} /> */}
              <Route exact path={["/splash-page"]} component={Preview} />
              <Route exact path={"/proceed"} component={Proceed} />
              <Route exact path={"/unqualified"} component={Unqualified} />
              <Route exact path={"/signature"} component={Signature} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyouu} />
              <Route exact path={"/buds-redirect-page"} component={BudsRedirectPage} />
              <Route exact path={["/preview"]} component={PreviewLoa} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={"/proceed-open-banking"} component={OpenBankingProceed} />
              <Route exact path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]} component={PdfView} />
              <Route exact path={["/Privacy-policy"]} component={Privacy_policy} />
              <Route exact path={["/Terms-of-use"]} component={Terms_of_use} />
              <Route exact path={["/Cookies-policy"]} component={Cookies_policy} />
              <Route exact path={["/ACA_SPH_V6B"]} component={ACA_SPH_V6B} />
              <Route exact path="/value/:dynamicValue" component={AdtopiaLoadValue} />
              <Route exact path={["/ACA_SPH_V8_4"]} component={ACA_SPH_V8_4} />
              <Route exact path={["/google", "/testing", "/index"]} component={AdtopiaLoadDynamic} />
              <Route exact path={"/greencard-thankyou"} component={GreencardThankyou} />
              <Route exact path={"/medicare-thankyou"} component={MedicareThankYou} />
              <Route exact path={["/ACA_SPH_V10"]} component={ACA_SPH_V10} />
              <Route exact path={"/medicare-thankyou-v2"} component={MedicareThankYouV2} />
              <Route exact path={["/ACA_SPH_V11"]} component={ACA_SPH_V11} />
              <Route exact path={"/ACA_BQ_ADV1"} component={ACA_BQ_ADV1} />
              <Route exact path={["/Política-de-Privacidad"]} component={Política_de_Privacidad} />
              <Route exact path={["/Política-de-Cookies"]} component={Política_de_Cookies} />
              <Route exact path={["/Terminos-de-Uso"]} component={Terminos_de_Uso} />
              <Route exact  path={["/ACA_SPH_V7"]} component={ACA_SPH_V7}/>
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
