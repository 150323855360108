import { useState } from "react";
import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useSignature = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const [isLoading, setIsLoading] = useState(false);
  const saveSignature = async (
    signature_data,
    visitor_parameters,
    form_data,
    data,
    query_string,
    message_type
  ) => {
    setIsLoading(true);
    const response = await Api.post("v1/data-ingestion-pipeline", {
      signature_data,
      visitor_parameters,
      form_data,
      data,
      query_string,
      message_type,
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Data Ingestion : ' + message_type,
        errorReason: error.message,
        errorCode: error.code,
        uuid: visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    setIsLoading(false);
    return response;
  };
  return {
    isLoading,
    saveSignature,
  };
};
