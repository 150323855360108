import React, { useContext, useEffect, useState } from "react";
import { CheckUUID } from "../../Utility/CheckUUID";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { userInfo } from "../../Hooks/userInfo";
import { FormData } from "../../Utility/FormData";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import logo from '../../assets/img/logo.png';
import loader from '../../assets/img/loader-review2.gif';
import { DynamicRoutingSplit } from "../../Utility/DynamicRoutingSplit";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import { AppDomain } from "../../Constants/EnvConstants";

const OpenBankingProceed = () => {
    // const { isCheck, } = CheckUUID();
    // const isCheckResponse = isCheck();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const history = useHistory();
    const [currentUuid, setCurrentUuid] = useState();
    const [bankName, setBankName] = useState();
    const [proceedBtn, setProceedBtn] = useState('block');
    const [selectBox,setSelectBox] = useState('hide');
    const [providers,setProviders] = useState();
    const [accessToken,setAccessToken] = useState();
    const [customerId,setCustomerId] = useState();
    const [selectedProvider,setSelectedProvider] = useState();
    const [loaderStatus,setLoaderStatus] = useState(false);
    const [btnDisable,setBtnDisable]   = useState(false);
    const [status,setStatus] = useState('');
    const { getUserInfo } = userInfo();
    const { setFormData } = FormData();
    const { getAccessToken , createCustomer, getProviders ,getAuthGatewayUrl,storeApiResponse }= useOpenBanking();
    const {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('','proceed');
    const query = new URLSearchParams(window.location.search);
    const query_source = query.get("atp_sub6");
    var source = (query_source) ? query_source : "live";
    
    function Capitalize(string){
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
      
    const getUuid = () =>{
        const visitorData = visitorParameters.visitor_parameters.uuid;
        if(visitorData){
            var uuid = visitorData;
        }
        else{
            const query_uuid = query.get("uuid");
            const local_storage_uuid = localStorage.getItem('uuid');
            var uuid = (query_uuid)? query_uuid : local_storage_uuid;
        }
        return uuid;
    }
  
    const uuid = getUuid();

    useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem('formData'));
    const userBank =  localStorage.getItem('bankName');
    setCurrentUuid(uuid);

    if(formdata ===  undefined || formdata === null || formdata == ''){
        (async () => {
            const response = await getUserInfo(
                uuid
            );
            console.log(response);
            const set_formdata = setFormData(
                response
            );
            let dataLength = (response.data.response).length;
            if(dataLength > 0) {
                localStorage.setItem('bankName',response.data.response[0].bank_name); 
                const bankName = (response.data.response[0].bank_name) ? Capitalize(response.data.response[0].bank_name) : '';
                setBankName(bankName);
            }
    
            })();
        }
    else
    {
    const bankName = Capitalize(userBank);
    setBankName(bankName);
    }
    }, [bankName]);

    const pushProceedLink = async () => {
        setLoaderStatus(true);
        setBtnDisable(true);
        setStatus("Fetching Providers...");
        const formdata = JSON.parse(localStorage.getItem('formData'));
        const response = await getAccessToken(uuid,source);
        if(response.data.status == "success"){
            setAccessToken(response.data.access_token_ID);
            let payload = {"milestone_key":"access_token","source":source};
            await storeApiResponse(visitorParameters.visitor_parameters,"op_milestone_update",currentUuid,payload);
            const customerResponse = await createCustomer(uuid,source,formdata.lstSalutation,formdata.txtFName,formdata.txtLName,formdata.txtEmail,formdata.txtPhone)
            if(customerResponse.data.status == "success"){
                localStorage.setItem('customer_id',customerResponse.data.customer_ID);
                setCustomerId(customerResponse.data.customer_ID);
                let payload = {"milestone_key":"customer_create","customer_id":customerResponse.data.customer_ID,'source':source};
                await storeApiResponse( visitorParameters.visitor_parameters,"op_customer_store",currentUuid,payload);  
                const providerResponse = await getProviders(uuid,source);
                if (providerResponse.data.status === "success") {
                    const providers = providerResponse.data.providers;
                    const optionData = providers.map((value, index) => {
                        return (
                          <option value={value.provider} key={index}>
                            {value.display_name}
                          </option>
                        );
                      });
                    setProviders(optionData);
                    setProceedBtn('hide');
                    setSelectBox('block');
                    setStatus("");
                    setLoaderStatus(false);
                }
            }
        } 
    }

    const submitProvider = async (provider) =>{
        let redirectUrl  = (query_source) ? AppDomain + "/buds-redirect-page?uuid="+currentUuid+"&atp_sub6="+query_source : AppDomain + "/buds-redirect-page?uuid="+currentUuid;
        let data = (provider) ? [selectedProvider] : "";
        var response = await getAuthGatewayUrl(uuid,customerId,redirectUrl,data,source)
        if(response.data.status == "success"){
            let url = response.data.gateway_url ;
            window.location.href = url;
        }
    }
    
    return (
        <>
        <GetVisitorsParams />
        <div className="preview">
        <div className="splash">
         <section className="py-4">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-12 text-center logo-part mt-5">
                     <img src={logo} alt="" className="opacity-25"/>
                  </div>
                  <div className="col-lg-12 col-12 text-center loader mt-4">
                    {loaderStatus ? <> <img  src={loader} alt=""/> </> : ""}
                     <p>
                        {status}

                     </p>
                     <div className="col-lg-6 col-12 offset-lg-3 text-center">
                        <a onClick={pushProceedLink} className={`btn-next ${proceedBtn}`} style={ { pointerEvents: btnDisable ? 'none' : '' } }  > Proceed </a>
                     </div>
                     <div className={` col-lg-6 col-12 offset-lg-3 text-center ${selectBox}`}>
                        <select className="form-select" onChange={(e)=>setSelectedProvider(e.target.value)}>
                            <option value="">Select</option>
                            {providers}
                        </select>
                     </div>
                     <div className={` col-lg-6 col-12 offset-lg-3 text-center ${selectBox}`}>
                        <a onClick={()=>submitProvider(true)} className="btn-next"> Submit </a>
                     </div>
                     <div className={` col-lg-6 col-12 offset-lg-3 text-center ${selectBox}`}>
                        <a onClick={()=>submitProvider(false)} className="btn-next"> Proceed to Bank List </a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        </div>
        </div>
        </>
    )
}

export default OpenBankingProceed;