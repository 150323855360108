import React, { useState, useEffect } from "react";
import { RingbaCheckUUID } from "../../Utility/RingbaCheckUUID";
import { Helmet } from "react-helmet";
import { MedicarePhoneNumberConstants } from "../../Constants/PhoneNumberConstants";
// import "../../assets/css/greencard-medicare-ty.scss";
import "../../assets/css/Modal.scss";
import { useUserClicks } from "../../Hooks/useUserClicks";
import useJourneyStats from "../../Hooks/useJourneyStats";
import OfflineSpanishButtonLink from "../../Utility/OfflineSpanishButtonLinkSpanish";
import RingbaApi from "../../Utility/RingbaApi";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import UnqualifiedRedirectionLinkSpanish from "../../Utility/UnqualifiedRedirectionLinkSpanish";
import loading_blue_infinity_logo from "../../assets/img/loading-blue-infinity-logo.svg";

const MedicareThankYouV2 = () => {
  const campaign_name = "medicare";
  const { saveDataIngestion } = useDataIngestion();
  const { isCheck } = RingbaCheckUUID();
  isCheck();
  const { response } = RingbaApi(campaign_name);
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("uuid");
  const utmName = query.get("utm_campaign")
    ? query.get("utm_campaign")
    : "default";
  const [formattedNumber, setFormattedPhoneNumber] = useState("");
  const utm_phonenumber = MedicarePhoneNumberConstants[utmName]
    ? MedicarePhoneNumberConstants[utmName]
    : MedicarePhoneNumberConstants["default"];
  const [phoneNumber, setPhoneNumber] = useState(utm_phonenumber);
  const { saveUserClicks } = useUserClicks();
  const saveJournyStats = useJourneyStats();
  const unqualifiedLink = UnqualifiedRedirectionLinkSpanish();
  const [hide, setHide] = useState("show");

  const [buttonRedirect, setButtonRedirect] = useState({});
  let is_online = response.is_online;
  let redirect = response.redirect_path;

  console.log(is_online);

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);

  useEffect(() => {
    if (phoneNumber) {
      const digits = phoneNumber.replace(/\D/g, "").slice(-10);
      if (digits.length === 10) {
        const formattedNumber = digits.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "$1-$2-$3"
        );
        setFormattedPhoneNumber(formattedNumber);
      }
    }
  }, [phoneNumber]);

  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const splitName = localStorage.getItem("split_name");
  useEffect(() => {
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "MEDICARE_CLICK",
        "",
        "view",
        splitName
      );
    })();
  }, []);
  const saveCallClick = () => {
    saveUser("MEDICARE_CLICK", 1);
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "MEDICARE_CLICK",
        "",

        "click",
        splitName
      );
    })();
    setHide("hide");
  };
  const handleRedirect = async (click_status, click_value) => {
    const ringbaSubmit = await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      splitName
    );
    console.log(buttonRedirect.buttonRedirectionLink);
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };

  const [index, setIndex] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndex(1);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  //   const ModalViewers =  async(click_status) =>{
  //     const viewersSubmit = await saveDataIngestion(
  //            uuid,
  //            [click_status],
  //            "unqualify_modal",
  //            "ACA_SPH_V3",
  //     );
  //     window.location = unqualifiedLink.redirectionLink;
  // }

  const modalClose = () => {
    setHide("hide");
  };

  return (
    <>
      <Helmet>
        <script
          src="//b-js.ringba.com/CA4c33f7b5cf4046a08df3e56570d18491"
          async
        ></script>
      </Helmet>

      <div className="greencard-medicare-ty">
        <iframe
          src={`${unqualifiedLink.redirectionLink}`}
          title="Answer Junkie Medicare"
          width="100%"
          height="500px"
          frameBorder="0"
          scrolling="auto"
          className="iframemedi"
          onLoad={() => setIndex(1)}
        ></iframe>
        <div
          className={`modal fade unqlifiedmodal-pop ${hide}`}
          tabIndex={-1}
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content bor-b5">
              {/* <div className='modal-header noborder' /> */}
              <div className="modal-body">
                <div className="unqualify-modal-p text-center fw-bold mt-4">
                  {index === 0 ? (
                    <div className="mb-3">
                      <img
                        src={loading_blue_infinity_logo}
                        alt="loader"
                        width={150}
                      />
                    </div>
                  ) : (
                    <>
                      <h5>
                        <b> ¡Felicidades! 🤩🤩🥳🥳🥳</b>
                      </h5>
                      <h6>
                        Toca el botón de abajo para llamar ahora y reclama tus
                        beneficios, sólo te llevará de 2 a 3 minutitos{" "}
                      </h6>
                    </>
                  )}
                </div>
              </div>

              {index !== 0 && (
                <>
                  {is_online === "online" ? (
                    <div className="modal-footer justify-content-center">
                      <div className="msg-loader">
                        <div className="btn-animate">
                          <a
                            className="link"
                            href={`tel: ${phoneNumber}`}
                            onClick={saveCallClick}
                          >
                            {formattedNumber}
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="modal-footer justify-content-center">
                      <div className="msg-loader">
                        <div className="btn-animate">
                          <a
                            className="link"
                            target="_blank"
                            onClick={(event) => {
                              event.preventDefault();
                              handleRedirect(1, "call_offline_medicare");
                            }}
                          >
                            Reclamar Beneficios
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MedicareThankYouV2;
